<template>
  <transition name="modal-animate" @after-enter="showContent = true">
    <div class="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
      v-if="value.open">
      <!-- Modal -->
      <transition appear enter-active-class="transition duration-200 ease-out"
        enter-class="transform translate-y-1/2 opacity-0" enter-to-class="opacity-100"
        leave-active-class="transition duration-200 ease-in" leave-class="opacity-100"
        leave-to-class="transform translate-y-1/2 opacity-0">
        <div
          class="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
          role="dialog" id="modal">
          <!-- @click.away="closeModal" -->
          <!-- Remove header if you don't want a close icon. Use modal body to place modal tile. -->
          <header class="flex justify-end">
            <button
              class="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700 focus:outline-none"
              aria-label="close" @click="closeModal">
              <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" role="img" aria-hidden="true">
                <path
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd" fill-rule="evenodd"></path>
              </svg>
            </button>
          </header>
          <!-- Modal body -->
          <div slot="modal_body" class="flex flex-col mt-4 mb-6 space-y-5">
            <!-- Modal title -->
            <div>
              <p class="mb-2 text-lg text-gray-700 dark:text-gray-300">
                Clear Pending for
                <span class="font-semibold">{{ value.customer.name }}</span>
              </p>
              <p class="mb-2 text-gray-700 dark:text-gray-300">
                Total pending ₹{{ value.customer.total_pending }}
              </p>
            </div>
          </div>
          <footer
            class="flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800">
            <button @click="closeModal"
              class="w-full px-5 py-3 text-sm font-medium leading-5 text-gray-700 transition-colors duration-150 border border-gray-300 rounded-lg dark:text-gray-400 sm:px-4 sm:py-2 sm:w-auto active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray">
              Cancel
            </button>
            <button
              class="w-full px-5 py-3 text-sm font-medium text-white bg-indigo-600 rounded-lg sm:w-auto sm:px-4 sm:py-2 focus:outline-none dark:bg-indigo-500"
              @click="makePayment">
              Clear pending payment
            </button>
          </footer>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ClearPendingPaymentModal",
  data() {
    return {
      amount: "",
    };
  },
  components: {},
  computed: {},
  props: {
    value: {
      required: true,
      type: Object,
    },
  },

  methods: {
    closeModal() {
      this.value.open = false;
      this.value.customer = {};
    },
    makePayment() {
      this.$store
        .dispatch("customer/clearPendingPayment", {
          nano_id: this.value.customer.nano_id,
        })
        .then((res) => (res.success ? this.closeModal() : null));
    },
  },
};
</script>

<style>

</style>

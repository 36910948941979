<template>
  <div class="w-full overflow-hidden">
    <div class="flex items-center flex-col space-y-4 lg:space-y-0 lg:flex-row lg:space-x-5 my-5">
      <div>
        <h2 class="text-2xl font-semibold text-gray-700 dark:text-gray-200">
          Customers with pending payments
        </h2>
      </div>
      <div>
        <router-link
          class="text-indigo-700 dark:text-indigo-400 border rounded-xl dark:border-indigo-400 border-indigo-700 px-2 py-1"
          to="/customers/create">
          Create new customer
        </router-link>
      </div>
      <div class="w-full md:w-3/4 lg:w-1/2 2xl:w-1/4">
        <div class="relative">
          <button
            class="absolute inset-y-0 left-0 px-2  text-white transition-colors duration-150 bg-indigo-600 border border-transparent rounded-l-xl hover:bg-indigo-700 focus:outline-none dark:bg-primary-light "
            @click="
  loadCustomers();
search = '';
            ">
            Reset
          </button>
          <input class=" pr-20  text-sm input pl-16 h-8" placeholder="Search customer by name"
            @keyup.enter="searchCustomer()" v-model="search" />
          <button
            class="absolute inset-y-0 right-0 px-4 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-indigo-600 border border-transparent rounded-r-xl hover:bg-indigo-700  dark:bg-primary-light focus:outline-none"
            @click="searchCustomer()">
            Search
          </button>
        </div>
      </div>
    </div>
    <div class="rounded-xl border border-gray-300 dark:border-gray-600 overflow-hidden">
      <div class="w-full overflow-x-auto">
        <table class="w-full whitespace-no-wrap">
          <thead>
            <tr
              class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-300 dark:text-gray-400 dark:bg-gray-700">
              <th class="px-4 py-3">Name</th>
              <th class="px-4 py-3">Verified</th>
              <th class="px-4 py-3">Pending</th>
              <th class="px-4 py-3 text-center">Actions</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800" v-if="customers.length > 0">
            <tr v-for="customer in customers" :key="customer.nano_id" class="text-gray-700 dark:text-gray-400">
              <td class="px-4 py-3">
                <div class="flex items-center">
                  <div class="flex flex-col">
                    <p class="font-semibold">{{ customer.name }}</p>
                    <p class="text-sm font-medium font-mono">
                      {{ customer.mobile }}
                    </p>
                  </div>
                </div>
              </td>

              <td class="px-4 py-3 text-xs">
                <span class="px-2 py-1 font-semibold leading-tight capitalize rounded-full" :class="
                  customer.verified
                    ? 'text-green-700 bg-green-100 dark:bg-green-700 dark:text-green-100'
                    : 'text-red-700 bg-red-100 dark:bg-red-700 dark:text-red-100'
                ">
                  {{ customer.verified ? "verified" : "unverified" }}
                </span>
              </td>
              <td class="px-4 py-3 text-sm font-normal font-mono" v-if="customer.total_pending">
                ₹{{ customer.total_pending }}
              </td>
              <td v-else class="px-4 py-3 text-sm font-mono">
                ₹0.00
              </td>

              <td class="px-4 py-3">
                <div class="flex items-center justify-center space-x-4 text-sm">
                  <div v-if="customer.total_pending > 0.0"
                    class="flex items-center justify-center space-x-2 text-sm pt-2">
                    <button
                      class="flex items-center space-x-2 w-40 px-2 py-1 text-sm font-medium text-green-600 rounded-full dark:text-green-400 focus:outline-none border border-green-600 dark:border-green-400"
                      aria-label="clear pending" @click="openCustomerPaymentModal(customer)">
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                          class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M9 8h6m-5 0a3 3 0 110 6H9l3 3m-3-6h6m6 1a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </span>
                      <span>Make Payment</span>
                    </button>
                    <button
                      class="flex items-center space-x-2 w-36 px-2 py-1 text-sm font-medium text-red-600 rounded-full dark:text-red-400 focus:outline-none border border-red-600 dark:border-red-400"
                      aria-label="clear pending" @click="openClearCustomerPaymentModal(customer)">
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                          class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M9 8h6m-5 0a3 3 0 110 6H9l3 3m-3-6h6m6 1a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>
                      <div>
                        Clear Pending
                      </div>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
            <tr class="text-gray-700 dark:text-gray-400">
              <td class="px-4 py-3 text-center" colspan="4">
                <p class="font-semibold">No data</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="grid px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-300 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-700">
        <span class="flex items-center col-span-3">
          Showing
          {{
            pagination.meta
              ? `${pagination.meta.from}-${pagination.meta.to} of ${pagination.meta.total}`
              : null
          }}
        </span>
        <span class="col-span-2"></span>
        <!-- Pagination -->
        <span class="flex col-span-4 mt-2 sm:mt-auto sm:justify-end">
          <nav aria-label="Table navigation">
            <ul class="inline-flex items-center">
              <li>
                <button :class="
                  pagination.links && pagination.links.prev
                    ? 'text-gray-600 dark:text-gray-400'
                    : 'text-gray-400 dark:text-gray-500'
                " :disabled="pagination.links && !pagination.links.prev"
                  class="px-2 py-1 rounded-md rounded-l-lg focus:outline-none" aria-label="Previous"
                  @click="loadCustomers(pagination.meta.current_page - 1)">
                  <svg class="w-4 h-4 fill-current" aria-hidden="true" viewBox="0 0 20 20">
                    <path
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd" fill-rule="evenodd"></path>
                  </svg>
                </button>
              </li>
              <li>
                <button :class="
                  pagination.links && pagination.links.next
                    ? 'text-gray-600 dark:text-gray-400'
                    : 'text-gray-400 dark:text-gray-500'
                " :disabled="pagination.links && !pagination.links.next"
                  class="px-3 py-1 rounded-md rounded-r-lg focus:outline-none focus:shadow-outline-indigo"
                  aria-label="Next" @click="loadCustomers(pagination.meta.current_page + 1)">
                  <svg class="w-4 h-4 fill-current" aria-hidden="true" viewBox="0 0 20 20">
                    <path
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd" fill-rule="evenodd"></path>
                  </svg>
                </button>
              </li>
            </ul>
          </nav>
        </span>
      </div>
    </div>
    <payment-modal v-if="customerPayment.open" v-model="customerPayment">
    </payment-modal>
    <clear-payment-modal v-if="clearCustomerPayment.open" v-model="clearCustomerPayment">
    </clear-payment-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PaymentModal from "./PaymentModal";
import ClearPaymentModal from "./ClearPendingModal";

export default {
  name: "CustomersComponent",
  data() {
    return {
      search: "",
      customerPayment: {
        open: false,
        customer: {},
      },
      clearCustomerPayment: {
        open: false,
        customer: {},
      },
    };
  },
  components: {
    PaymentModal,
    ClearPaymentModal,
  },
  mounted() {
    this.loadCustomers();
  },
  computed: {
    ...mapGetters({
      customers: ["customer/getPendingCustomers"],
      user: ["auth/getAuthUser"],
      pagination: ["customer/getPendingPagination"],
    }),
  },
  methods: {
    searchCustomer() {
      if (this.search.length > 2) {
        this.$store.dispatch("customer/getCustomers", {
          search: this.search,
        });
      }
    },
    loadCustomers(page = null) {
      this.$store.dispatch("customer/getCustomers", {
        page,
        sortbypending: true,
      });
    },
    deletecustomer(nano_id) {
      this.$store.dispatch("customer/deleteCustomer", { nano_id });
    },
    openCustomerPaymentModal(customer) {
      this.customerPayment.customer = { ...customer };
      this.customerPayment.open = true;
    },
    openClearCustomerPaymentModal(customer) {
      this.clearCustomerPayment.customer = { ...customer };
      this.clearCustomerPayment.open = true;
    },
  },
  filters: {
    humanDate(date) {
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      const today = new Date(date);
      return today.toLocaleDateString("en-IN", options);
    },
  },
};
</script>

<style>

</style>
